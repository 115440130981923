.c-status-indicator {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
}

.c-status-indicator__color {
  border-radius: var(--g-border-round);
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-status-indicator__color--active {
  background-color: var(--g-color-blue-100);
}

.c-status-indicator__color--inactive {
  background-color: var(--g-color-grey-500);
}

.c-status-indicator__color--request {
  background-color: var(--g-color-red-400);
}

.c-status-indicator__status {
  text-transform: capitalize;
}
