.c-layout__background {
  background-image: url('/images/background-whole.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  padding: var(--g-spacing);
}

.c-layout__content-container {
  align-items: center;
  background-color: var(--g-color-white);
  border-radius: var(--g-border-radius-medium);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: 0;
  height: 90%;
  padding: 0 var(--g-spacing-2x-large);
  width: 90%;
}

.c-layout__content-body {
  overflow: auto;
  flex: 1;
  overflow: auto;
}


.c-layout__content-header {
  padding-top: var(--g-spacing-6x-large);
  position: relative;
}

.c-layout__content-header-text {
  font-weight: var(--g-font-weight-bold);
  margin: 0;
}

.c-layout__content-body,
.c-layout__content-footer,
.c-layout__content-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.c-layout__content-footer {
  justify-content: flex-end;
}

.c-layout__content-footer-link {
  color: var(--g-color-brand-tertiary);
  font-size: var(--g-font-size-small);
  font-weight: var(--g-font-weight-semibold);
}

.c-layout__content-footer-link-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: var(--g-spacing);
}

.c-layout__content-header-product-logo {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}

.c-layout__content-header-product-logo__image__image {
  max-height: calc(var(--g-spacing) * 8);
  width: auto;
}

.c-layout__content-footer,
.c-layout__content-header {
  align-items: center;
  text-align: center;
}

.c-layout__content-header-description {
  font-family: var(--g-font-primary);
  font-weight: var(--g-font-weight-light);
  margin-bottom: 0;
  margin-top: var(--g-spacing);
}

@media (min-width: 36em) {
  .c-layout__content-container {
    padding-bottom: var(--g-spacing-2x-small);
    max-width: 36rem;
    margin-top: var(--spacing-24);
    position: fixed;
    left: 0%;
    right: 0%;
    bottom: 0;
    height: 85%;
  }

  .c-layout__content-body {
    overflow: auto;
  }

  .c-layout__content--padded {
    padding: 0 6rem;
  }

  .c-layout__content-header-text {
    font-size: 4.625rem;
    line-height: 1.1;
  }

  .c-layout__content-header-text.is-small {
    font-size: 3.6875rem;
  }

  .c-layout__content-header-product-logo__image {
    align-items: center;
    display: flex;
    justify-content: center;
  }

}
