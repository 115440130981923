.c-step-counter__step,
.c-step-counter__completion-time {
  font-family: var(--g-font-primary);
  font-weight: var(--g-font-weight-bold);
  font-size: var(--g-font-size);
  margin: 0;
}

.c-step-counter__completion-time {
  align-items: center;
  border-radius: 2em;
  background-color: var(--g-color-grey-50);
  display: flex;
  padding: var(--g-spacing-2x-small) var(--g-spacing-x-small);
  gap: var(--g-spacing-2x-small);
}

.c-step-counter__icon-container {
  align-items: center;
  background-color: var(--g-color-brand);
  color: var(--g-color-white);
  border-radius: 50%;
  display: flex;
  height: var(--g-spacing-2x-large);
  justify-content: center;
  padding: var(--g-spacing-3x-small);
  width: var(--g-spacing-2x-large);
}

.c-step-counter__icon {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-step-counter__time-text {
  margin-right: var(--g-spacing-x-small);
}
