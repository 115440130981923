.c-complete-verification {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: var(--g-spacing-4x-large) 0 var(--g-spacing-3x-large);
}

.c-complete-verification__image {
  height: 125px;
  margin-bottom: var(--g-spacing-large);
  width: auto;
}

.c-complete-verification__text {
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-light);
  line-height: 1.18;
  margin: 0;
  text-align: center;
}


