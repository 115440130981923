
.c-scan-qr-code__title,
.c-scan-qr-code__list,
.c-scan-qr-code__text,
.c-scan-qr-code__button,
.c-scan-qr-code__secret {
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
}

.c-scan-qr-code__title {
  margin-bottom: var(--g-spacing-x-small);
}

.c-scan-qr-code__list,
.c-scan-qr-code__text,
.c-scan-qr-code__button {
  font-weight: var(--g-font-weight-light);
  line-height: 1.18;
  margin: 0;
}

.c-scan-qr-code__secret {
  display: flex;
  align-items: center;
  height: 150px;
}

.c-scan-qr-code__list li {
  line-height: 1.35;
}

.c-scan-qr-code__button {
  border: none;
  background-color: transparent;
  color: var(--color-primary);
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  font-weight: var(--g-font-weight-light);
}

.c-scan-qr-code__details {
  margin: var(--g-spacing-4x-large) var(--g-spacing-x-large) var(--g-spacing-3x-large);
  text-align: center;
}

.c-scan-qr-code__image {
  height: 150px;
  margin-bottom: var(--g-spacing-large);
  width: 150px;
}

