.c-registration-layout {
  min-height: 26rem;
  max-width: 23rem;
  margin: auto;
  position: relative;
  margin-top: 5rem;
  padding: var(--g-spacing-x-small);
  animation-name: fade-in;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}

.c-registration-layout--fade-up {
  animation-name: fade-up;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}


.c-registration-layout__title {
  font-weight: var(--g-font-weight-bold);
  font-size: var(--g-font-size-5x-large);
  font-family: var(--g-font-primary);
  line-height: 1;
  margin-bottom: var(--g-spacing-x-small);
}

.c-registration-layout__title--centered {
  margin-top: var(--g-spacing-small);
  text-align: center;
}

.c-registration-layout__description {
  font-weight: var(--g-font-weight-light);
  font-size: var(--g-font-size);
  font-family: var(--g-font-primary);
  margin-bottom: var(--g-spacing-x-small);
  line-height: 1.5;
}

.c-registration-layout__description--centered {
  text-align: center;
}

.c-registration-layout__login-container {
  display: flex;
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-light);
  font-family: var(--g-font-primary);
  justify-content: center;
  padding-bottom: var(--g-spacing-2x-large);
  padding-top: var(--g-spacing-2x-large);
}

.c-registration-layout__login-container a {
  color: var(--g-color-brand-tertiary);
}

.c-registration-layout--mobile {
  margin-top: 0;

  .c-registration-layout__login-container {
    padding-bottom: var(--g-spacing-x-small);
  }
}

.c-registration-layout__back-button {
  border: none;
  background-color: transparent;
  color: var(--g-color-brand-tertiary);
  cursor: pointer;
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-light);
  line-height: 1.18;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

.c-registration-layout .c-error-message {
  margin: 0;
  padding: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
