.c-register-flow {
  background-color: var(--g-color-white);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-register-flow__header {
  min-height: 6rem;
  background: var(--g-color-white);
  background-color: var(--g-color-white);
  padding-top: var(--g-spacing-large);
}

.c-register-flow__main {
  position: relative;
  flex: 1 1;
}

.c-register-flow__container {
  min-height: 30rem;
  width: 23rem;
  margin: auto;
  margin-top: 8rem;
}

.c-register-flow__domain-selector {
  width: 23rem;
  margin: auto;
}

.c-register-flow__header .c-app-header__image {
  height: calc(var(--g-spacing) * 5);
}

.c-registration-flow__footer {
  padding: var(--g-spacing);
  display: flex;
  justify-content: center;
}
