/* stylelint-disable selector-class-pattern */
.bg-gradient {
  background: linear-gradient(45deg, #173567, #972868);
}

.bg-gradient-wave {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-gradient-wave--whole {
  background-image: url(/images/background-whole.svg);
}

.bg-gradient-wave--minimal {
  background-image: url(/images/background-gradient-minimal.svg);
}

.c-contact-form__form .c-form__body {
  margin-bottom: var(--g-spacing);
}

.c-contact-form__form .c-form__body .c-input__container {
  margin-bottom: var(--g-spacing);
}

.c-error-message {
  color: var(--g-color-red-600);
  font-size: var(--g-font-size-small);
  padding: 0 0 var(--g-spacing-small) 0;
}

.c-contact-form__form .c-form__footer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.c-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 81.25rem;
  width: 85%;
}

.c-container--xs {
  max-width: 48rem;
}

.c-container--small {
  max-width: 62.5rem;
}

.c-container--large {
  max-width: 100rem;
}

.c-container--no-padding {
  padding: 0 !important;
}

.c-copy-button:hover {
  cursor: pointer;
}

.c-input--is-error {
  border-color: var(--g-color-red-600);
}

.c-list-items {
  padding-left: 0;
}

.c-password__button {
  top: 4.5rem;
}

.c-inputs-toolbar {
  margin-bottom: var(--g-spacing-x-small);
}

.c-inputs-toolbar .c-input-field {
  display: flex;
  align-items: center;
}

.c-select-with-search__users-table .c-select-with-search__cross-button {
  top: var(--g-spacing);
  background-color: transparent;
}

.c-select-with-search__users-table .c-select-with-search__search-icon {
  top: var(--g-spacing);
}

.c-select-with-search__users-table .c-dropdown-toggle,
.c-select-with-search__users-table .c-button-list__item {
  cursor: pointer;
  background-color: var(--g-color-white);
}

.c-select-with-search__users-table .c-button-list__item:hover,
.c-select-with-search__users-table .c-button-list__item:focus {
  background-color: var(--g-color-grey-100);
}

.c-table .c-table__tbody .c-table__tbody-tr .c-table__tbody-td {
  white-space: normal;
}
.c-table .c-table__tbody .c-table__tbody-tr:nth-child(even) {
  background-color: unset;
}

.c-input-with-cancel-button {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  height: var(--g-spacing-2x-large);
  justify-self: center;
  position: relative;
  width: var(--g-spacing-2x-large);
  background: transparent;
  right: var(--spacing-9);
}

.c-input-with-cancel-button__cross-icon {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-sidebar-navigation-button {
  background-color: var(--g-color-white);
  cursor: pointer;
}

.c-user-status-toggle .c-label {
  font-weight: var(--g-font-weight-light);
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
}

.c-email-status-tooltip .c-tooltip__trigger .c-tooltip__icon {
  background: none !important;
  border-radius: unset;
  width: var(--g-spacing-x-large);
  height: var(--g-spacing-x-large);
  padding: 0;
}

.c-email-status-tooltip .c-tooltip__trigger {
  background: none;
}

.c-email-status-tooltip .c-tooltip__trigger:focus {
  box-shadow: none;
}

.c-settings-button {
  border: 1px solid var(--g-color-grey-100);
}

.c-settings-button:hover {
  cursor: pointer;
}

.c-ui-graphic {
  max-width: 100%;
  position: relative;
}

.c-page-preview-card--is-hero > .c-page-preview-card__title {
  font-size: 5rem;
  line-height: 5rem;
}

.c-pagination-user-management {
  margin: 0 auto;
  margin: var(--g-spacing) 0 var(--g-spacing-4x-large) 0;
  flex-direction: unset;
}

.c-pagination-user-management .c-pagination__page-jumper {
  white-space: nowrap;
  margin: 0 auto;
}

.c-pagination__page-jumper__page-size .c-input-field {
  display: flex;
  align-items: center;
}

.c-pagination__page-jumper__page-size p {
  margin: 0;
}

.c-role-indicator {
  position: absolute;
  top: 0;
}

.c-admin-star {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
}

.c-admin-star .c-tooltip__trigger {
  background: transparent;
  border: none;
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-admin-star .c-tooltip__trigger .c-tooltip__icon {
  background: transparent;
  padding: 0;
  height: var(--g-spacing);
  width: var(--g-spacing);
  color: #ffc96c;
}

.c-admin-star--is-admin .c-tooltip__trigger .c-tooltip__icon {
  color: #72acff;
}

.c-sidebar__container {
  padding-bottom: var(--g-spacing-6x-large);
}

.l-container {
  display: block;
}

.c-app-header__logo-icon {
  display: none;
}

.c-sidebar__container--left {
  position: absolute;
  top: 0;
}

@media (min-width: 1024px) {
  .c-ui-graphic {
    position: absolute;
    right: 0;
    top: 50%;
    max-width: 42rem;
    transform: translate(40%, -40%);
  }
}

@media (min-width: 1300px) {
  .c-ui-graphic {
    transform: translate(0, -40%);
  }
}

.c-invite-users-mapping-entries {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.c-invite-users-mapping-entry {
  display: flex;
  gap: 30px;
  align-items: center;
}

.c-invite-users-mapping-entry > div > .c-input__label {
  display: none;
}

.c-dashboard-organisation-controller {
  background-color: var(--g-color-white);
}

.c-date-input {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}

.c-features-dropdown .c-dropdown-toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.c-register-page__terms-link {
  color: var(--g-color-brand-tertiary) !important;
}

.c-sidebar-nav__page-wrapper {
  width: auto !important;
}

.h-font-weight-bold {
  font-weight: var(--g-font-weight-bold);
}

.c-page-container {
  max-width: 125rem;
  margin: 0 auto;
}

.c-tablist__nav {
  padding: 0 var(--g-spacing-2x-large) 0 var(--g-spacing-small);
}

.c-sidebar-nav__page-wrapper.is-pushed-right > .c-page-container {
  margin: 0 auto;
  transform: translateX(-7%);
  transition: transform var(--g-transition-time) ease-in-out;
}

@media (min-width: 28em) {
  .c-sidebar-nav__page-wrapper.is-pushed-right {
    margin-left: calc(var(--c-left-sidebar-max-width) - calc(var(--g-spacing) * 8));
  }
}

.c-sidebar-toggle .c-label {
  justify-content: space-between;
}

.c-sidebar-toggle {
  width: 100%;
}

.c-sidebar-toggle .c-toggle-switch__slider:before {
  left: 0;
}

.c-custom-field-icon-wrapper {
  align-items: center;
  color: var(--g-color-white);
  background-color: var(--g-color-purple-500);
  display: flex;
  justify-content: center;
  padding: var(--g-spacing-2x-small);
  border-radius: 50%;
}

.c-custom-field-icon-wrapper--not-required {
  background-color: var(--g-color-orange-400);
}


.c-custom-field-icon {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

@media (max-width: 38em) {
  .c-sidebar__container--is-hidden--left {
    transform: translateX(-100%);
  }
}

.c-toolbar__item > .c-input-field {
  width: 100%;
}

.h-flex-shrink-0 {
  flex-shrink: 0;
}


.h-word-break {
  word-wrap: break-word;
}

.c-progress-tracking-button {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding: var(--g-spacing-2x-small);
}

.c-progress-tracking-button .c-button__icon {
  height: var(--g-spacing) !important;
  width: var(--g-spacing) !important;
}

.c-sidebar__toggle-reveal-button {
  top: 5rem !important;
}

.c-tooltip__bubble {
  text-align: left;
}


.c-button-icon-only--disabled {
  background-color: var(--g-color-grey-100) !important;
}