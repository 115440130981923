.c-user-account-menu .c-user-navigation .c-dropdown-navigation__list-item {
  display: flex;
  align-items: flex-start;
  font-size: var(--g-font-size-small);
  padding: var(--g-spacing-x-small) var(--g-spacing);
}

.c-user-account-menu .c-user-navigation .c-dropdown-navigation__list-item:first-child {
  cursor: auto;
}

.c-user-account-menu .c-user-navigation .c-dropdown-navigation__list-item:first-child:hover {
  background-color: transparent !important;
}

.c-user-account-menu .c-user-navigation .c-dropdown-navigation__list-item-button {
  cursor: auto;
  background-color: transparent;
  padding: 0;
}

.c-user-account-menu .c-dropdown-toggle--open {
  background-color: #173467;
  border-radius: var(--g-border-radius-small);
}

.c-user-account-menu .c-dropdown-toggle--open {
  padding-bottom: var(--g-spacing-2x-small);
}
