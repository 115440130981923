.c-confirm-mfa-code__label,
.c-confirm-mfa-code__text,
.c-confirm-mfa-code__button {
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-light);
  line-height: 1.18;
  margin: 0;
}

.c-confirm-mfa-code__label {
  text-align: center;
}

.c-confirm-mfa-code__button {
  border: none;
  background-color: transparent;
  color: var(--color-primary);
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}
