:root {
  /* Fonts */
  --font-header: rift;
  --font-body: calibri;

  /* COLOURS */
  --black: #000;
  --burgandy-400: #972868;
  --grey-900: #2a2e2f;
  --grey-800: #42536e;
  --grey-400: #a8b2b4;
  --grey-300: #cbccce;
  --grey-200: #ecedf0;
  --grey-100: #f6f6f7;
  --green-400: #4bc2ca;
  --green-500: #39acb4;
  --green-600: #239098;
  --green-700: #15666c;
  --green-400: #72acff;
  --color-primary: #4bc2ca;
  --red-400: #f44336;
  --red-300: #ff4d4d;
  --yellow-400: #ffc96c;
  --white: white;

  /* SPACING */
  --spacing-base: 0.25rem;
  --spacing-0-5: calc(var(--spacing-base) / 2);
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4); /* 1rem */
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8); /* 2rem */
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12); /* 3rem */
  --spacing-13: calc(var(--spacing-base) * 13);
  --spacing-14: calc(var(--spacing-base) * 14);
  --spacing-15: calc(var(--spacing-base) * 15);
  --spacing-16: calc(var(--spacing-base) * 16); /* 4rem */
  --spacing-17: calc(var(--spacing-base) * 17);
  --spacing-18: calc(var(--spacing-base) * 18);
  --spacing-19: calc(var(--spacing-base) * 19);
  --spacing-20: calc(var(--spacing-base) * 20); /* 5rem */
  --spacing-24: calc(var(--spacing-base) * 24); /* 6rem */
  --spacing-28: calc(var(--spacing-base) * 28); /* 7rem */
  --spacing-32: calc(var(--spacing-base) * 32); /* 8rem */
  --spacing-36: calc(var(--spacing-base) * 36); /* 9rem */
  --spacing-40: calc(var(--spacing-base) * 40); /* 10rem */
  --spacing-48: calc(var(--spacing-base) * 48); /* 11rem */
  --spacing-52: calc(var(--spacing-base) * 52); /* 13rem */
  --spacing-60: calc(var(--spacing-base) * 60); /* 15rem */
  --spacing-72: calc(var(--spacing-base) * 72); /* 18rem */
  --spacing-80: calc(var(--spacing-base) * 80); /* 20rem */
  --spacing-88: calc(var(--spacing-base) * 88); /* 22rem */

  /* COMPONENTS */
  --g-invited-user-list-max-height: 10.4rem;

  /* FONTS */
  --g-font-primary: 'calibri';
  --g-font-secondary: 'rift';
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: var(--g-font-primary);
}

h2 {
  font-family: 'rift';
  font-size: var(--g-font-size-5x-large);
}

.c-error-message {
  color: var(--g-color-red-600);
  margin: var(--g-spacing-small) 0;
  font-size: var(--g-font-size-small);
}

.c-form__body {
  margin-bottom: var(--g-spacing-3x-large);
}

.c-form__header {
  margin-bottom: var(--g-spacing-5x-large);
  text-align: center;
}

.c-form__footer {
  text-align: center;
}

.c-form__footer .c-button {
  display: block;
  margin-left: auto;
}

.c-return-home {
  text-align: center;
}

.c-return-home a {
  color: var(--g-color-brand-tertiary);
  font-size: var(--g-font-size-small);
  font-weight: var(--g-font-weight-semibold);
}

@media (min-width: 36em) {
  .c-return-home {
    margin-bottom: var(--g-spacing);
  }
}

.c-login-form__heading,
.c-forgot-password-form__heading,
.c-profile-account-form__heading,
.c-registration-form__heading {
  font-family: 'rift';
  font-size: 2.25rem;
  line-height: 1.15;
  margin: 0 0 var(--g-spacing-x-small) 0;
  text-transform: uppercase;
}

.c-forgot-password-form__form .c-form__footer .c-button,
.c-reset-password-form__form .c-form__footer .c-button,
.c-update-password-form__form .c-form__footer .c-button {
  margin-right: auto;
}

.c-login-form__text {
  font-family: var(--g-font-primary);
  font-weight: var(--g-font-weight-light);
  text-align: center;
}

.c-input--is-error {
  border-color: var(--g-color-red-600);
}

.c-forgot-password-form__return-to-login-link-container {
  margin-top: var(--g-spacing);
}

.c-forgot-password-form__return-to-login-link-container,
.c-login-form__forgot-password-link-container {
  color: var(--g-color-brand-tertiary);
  font-size: var(--g-font-size-small);
  font-weight: var(--g-font-weight-light);
  margin-bottom: var(--g-spacing);
}

.c-login-form__register-link,
.c-registration-form__login-link {
  color: var(--g-color-brand-tertiary);
  font-weight: var(--g-font-weight-semibold);
}

.c-login-form__dont-have-an-account-text-container,
.c-registration-form__already-have-an-account-text-container {
  margin: var(--g-spacing-2x-large) 0 var(--g-spacing) 0;
}

.c-login-form__forgot-password-link-container,
.c-login-form__button {
  text-align: right;
}

.c-profile-account-form__buttons-container {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
  justify-content: center;
}

.c-update-password-form__buttons-container {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
  justify-content: center;
}

.c-delete-profile_buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

@media (min-width: 64em) {
  .c-login-form__heading,
  .c-profile-account-form__heading,
  .c-forgot-password-form__heading,
  .c-registration-form__heading {
    font-size: 3.5rem;
    line-height: 1.1;
  }
}

.c-avatar-title {
  align-items: center;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -ms-grid;
  display: grid;
  grid-template-columns: var(--g-spacing-3x-large) auto;
  -ms-grid-columns: var(--g-spacing-3x-large) auto;
}

.group-main-information-container {
  display: flex;
  gap: 20px;
}

.icon-text-side-to-side-container > p {
  margin-bottom: 0;
}

.icon-text-side-to-side-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.information-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.icon-text-side-to-side-container > svg {
  width: 20px;
  height: 20px;
}

.group-informations {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container-title {
  font-weight: bold;
}
.contacts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.group-detail-container {
  width: 100%;
  background: white;
  border: 1px solid #eff0f2;
  border-radius: 10px;
  padding: 1rem;
}

.group-detail-container-half {
  width: 48%;
  background: white;
  border: 1px solid #eff0f2;
  border-radius: 10px;
  padding: 1rem;
}

.codes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.smart-code,
.note,
.contact {
  padding: 10px;
  border: 1px solid #eff0f2;
  border-radius: 10px;
}
.chart-container {
  width: 50%;
}

.charts-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.linechart-chart {
  height: 270px;
  justify-content: center;
  display: flex;
}

.contact {
  display: flex;
  gap: 20px;
  min-width: 350px;
}

.contact > .contact-information {
  display: flex;
  flex-direction: column;
}

.contact-name {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.c-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.c-actions > * {
  cursor: pointer;
}

.c-convert-icon{
  transform: rotate(270deg);
  width: 20px;
  color: var(--color-primary);
  padding: 0px;
  height: 20px;
}


.c-information-box {
  background-color: var(--g-color-white);
}

.h-align-top-right {
  position: absolute;
  top: var(--g-spacing-x-large);
  right: var(--g-spacing-large);
}

.c-table__icon-container {
  align-items: center;
  border: 1px solid var(--g-color-black-900);
  border-radius: var(--g-border-radius-small);
  display: flex;
  height: var(--g-spacing-x-large);
  justify-content: center;
  padding: var(--g-spacing-2x-small);
  width: var(--g-spacing-x-large);
}


.h-no-white-space-wrap {
  white-space: nowrap;
}
