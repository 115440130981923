.c-validate-email__resend-code-container {
  display: flex;
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-light);
  font-family: var(--g-font-primary);
  justify-content: flex-start;
}


.c-validate-email__resend-code-button {
  border: none;
  background-color: transparent;
  color: var(--g-color-brand-tertiary);
  cursor: pointer;
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-light);
  line-height: 1.18;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
