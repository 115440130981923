
.c-password-criteria {
  color: var(--g-color-black-900);
  font-size: var(--g-font-size-small);
}

.c-password-criteria__text,
.c-password-criteria__list {
  font-family: var(--g-font-primary);
  margin: 0;
  padding: 0;
}

.c-password-criteria__text {
  font-weight: var(--g-font-weight-light);
}

.c-password-criteria__list {
  list-style: none;
  font-weight: var(--g-font-weight-light);
}

.c-password-criteria__icon {
  align-items: center;
  border-radius: 50%;
  color: var(--g-color-black-900);
  display: flex;
  justify-content: center;
  padding: 2px;
  background-color: var(--g-color-brand-tertiary);
}

.c-password-criteria__icon.is-invalid {
  color: var(--g-color-white);
  background-color: var(--g-color-red-600);
}

.c-password-criteria__icon svg {
  height: 0.75rem;
  width: 0.75rem;
}

.c-password-criteria__item {
  display: flex;
  align-items: center;
  margin: 0;
  gap: var(--g-spacing-2x-small);
}
