/* stylelint-disable selector-class-pattern */
.c-sidebar-list {
  margin: 0;
  margin-bottom: var(--g-spacing);
  padding: 0;
  width: 100%;
}

.c-sidebar-list__count {
  align-items: center;
  background-color: var(--g-color-grey-50);
  border: var(--g-border-primary);
  border-width: var(--g-spacing-3x-small);
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: var(--g-spacing-x-small);
}

.c-sidebar-list__item {
  align-items: center;
  border: var(--g-border-primary);
  border-width: var(--g-spacing-3x-small);
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing-x-small) var(--g-spacing-x-small) var(--g-spacing);
}

.c-sidebar-list__item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}


.c-sidebar-list__item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}


.c-sidebar-list__item--small {
  padding: var(--g-spacing-x-small);
}

.c-sidebar-list__item-content {
  display: flex;
  align-items: center;
  gap: var(--g-spacing-small);
  width: 100%;
}

.c-sidebar-list__item-label {
  font-size: var(--g-font-size);
}
